import React from 'react';
import logo from '../../Assets/Logo/Logo-white.png'
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';


const LogIn = () => {
    const [ error, setError ] = useState( '' )
    const navigate = useNavigate()

    const handleSubmit = ( e ) => {
        e.preventDefault();

        const form = e.target;

        const email = form.email.value;
        const password = form.password.value;

        fetch( `https://api.newstube.app/apiv3/api/login.php?nt_uemail=${ email }&nt_userpassword=${ password }` )
            .then( res => res.json() )
            .then( data => {
                // console.log( data );
                if ( data?.status ) {
                    navigate( '/' )
                    form.reset();
                }
                else {
                    setError( data?.message );
                }
            } )


    }
    return (
        <div className='min-h-screen flex justify-center items-center'>
            <div className='w-1/3 mx-auto bg-[#121111] flex flex-col justify-center items-center py-8 px-10 rounded-md'>
                <img src={ logo } alt="" className='w-1/3 mx-auto' />
                <h1 className='text-center text-2xl font-semibold my-3'>Login</h1>
                <form onSubmit={ ( e ) => handleSubmit( e ) }>
                    <input type="email" name='email' placeholder='Email' className='w-full mb-5 px-4 py-2 rounded-sm bg-[#242121e5] focus:outline-none' required />
                    <input type="password" name='password' placeholder='Password' className='w-full  px-4 py-2 rounded-sm bg-[#242121e5] focus:outline-none' required />
                    {
                        error && <p className='text-error mb-5'>{ error }</p>
                    }
                    <div className='flex items-center justify-between w-full'>
                        {/* <input type="checkbox" id="" /> */ }
                        <div className='flex gap-2 items-center text-lg'>
                            <input type="checkbox" className="h-5 w-5 rounded-md border bg-white border-gray-500" required />
                            <span>Remember me</span>
                        </div>
                        <div>
                            <h1 className='text-[#23B7E5]'>Forgot Password</h1>
                        </div>
                    </div>

                    <input type="submit" value="Log in" className='w-full bg-[#23B7E5] text-white py-2 mt-5 rounded-sm cursor-pointer' />
                </form>
                <div className='mt-5'>
                    <h1>Have no account? <Link to='/register' className='text-[#23B7E5]'>Register here</Link></h1>
                </div>
            </div>
        </div>
    );
};

export default LogIn;