import React from 'react';
import { FaPlay } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import './SingleDetailsCard.css'

const SingleDetailsCard = ( { video, i } ) => {
    return (
        <div className='relative cursor-pointer h-full duration-500 hover:gap-5 rounded-md card hover:z-20'>

            <img src={ `${ video?.nt_videothumbnail }` } alt="" className='relative h-full object-cover w-full rounded-md' />

            <div className='single_cardoverlay '>
                <div className='ml-5'>
                    <p className='mt-3 md:mt-5 lg:mt-8 text-xs lg:text-lg text-white font-normal lg:font-bold'>{ video?.nt_videotitle.length > 50 ? video?.nt_videotitle.slice( 0, 50 ) + '....' : video?.nt_videotitle }</p>

                    <h2 className='font-semibold lg:font-bold mt-2 text-sm lg:text-lg text-white'>Kanaksarwar</h2>
                    <h2 className='text-sm text-white'>{ video?.nt_categoryname }</h2>

                    <Link to={ `/videoplay/${ video?.nt_categoryname }/${ video?.nt_ytid }` }>

                        <button className='headerbtn mt-2 md:mt-2 lg:mt-5 flex justify-center rounded-sm items-center gap-2 text-white bg-[#E50914] hover:bg-red-700 text-center px-4 lg:px-12 py-2 lg:py-5'>
                            <FaPlay />
                            <span>Play Now</span>
                        </button>
                    </Link>
                </div>
            </div>
        </div >
    );
};

export default SingleDetailsCard;