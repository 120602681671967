import React, { useState, useEffect } from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import SingleDetailsCard from '../../Shared/SingleDetailsCard/SingleDetailsCard';

const LifeStyleNews = () => {
    const [ lifeStyleVideo, setLifeStyleVVideos ] = useState( [] );


    const url = 'https://api.newstube.app/apiv3/api/lifestyle.php'
    useEffect( () => {
        fetch( url )
            .then( res => res.json() )
            .then( data => {
                // console.log( data.body );
                setLifeStyleVVideos( data?.body )
            } )
    }, [] )




    return (
        <div className='w-11/12 mx-auto'>
            <hr className='mt-32 mb-5' />
            <div className='px-2'>
                <h1 className='text-4xl font-semibold text-white mb-5'>Life Style News</h1>
                <Splide
                    options={ {
                        type: 'loop',
                        autoplay: true,
                        perPage: 3,
                        breakpoints: {
                            1024: {
                                perPage: 3,
                                height: '170px',
                                gap: '10px'

                            },
                            768: {
                                perPage: 2,
                                height: '190px',
                            },
                            640: {
                                perPage: 2,
                                height: '150px',
                                gap: '10px'
                            }
                        },
                        speed: 2000,
                        perMove: 1,
                        height: '220px',
                        pagination: true,
                        gap: '10px',
                        // focus: 'center'

                    } }>
                    {
                        lifeStyleVideo?.map( ( video, i ) =>

                            <SplideSlide key={ i } className='splide__slide2'>

                                <SingleDetailsCard video={ video }></SingleDetailsCard>

                                {/* <SingleCard video={ video }></SingleCard> */ }

                                {/* <Link to={ `/videoplay/${ video?.nt_categoryname }/${ video?.nt_ytid }` } className=" rounded-lg cursor-pointer w-full">
                                    <div className="card bg-base-300 image-full w-full">
                                        <figure className='rounded-lg'><img src={ `${ video?.nt_videothumbnail }` } alt="news" className='h-full rounded-lg' /></figure>
                                        <div className="card-body h-[350px]">
                                            <h2 className="card-title text-white">{ video?.nt_videotitle }</h2>
                                        </div>
                                    </div>
                                </Link> */}
                            </SplideSlide>
                        )
                    }

                </Splide>
            </div>
        </div>
    );
};

export default LifeStyleNews;