import React from 'react';
import { useEffect } from 'react';

const AboutUs = () => {

    useEffect( () => {
        // window.scrollTo( 0, 0 )
    }, [] )

    return (
        <div className='w-11/12 mx-auto my-16'>
            <h1 className='text-3xl font-bold py-5 text-center underline'>About Us</h1>
            <p className='text-justify'>About us: News Tube News Tube is an open platform for all video content creators, including YouTube. This platform will accumulate News/ Political Talk Show about Democracy, Human Rights, Press Freedom, and good governance. Content creators worldwide will be privileged and welcome to upload their videos. Those who download this App will notify instantly about the New uploaded programs.</p>
        </div>
    );
};

export default AboutUs;