import React, { useEffect, useState } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import SingleNotificationVideo from '../../Components/SingleNotificationVideo/SingleNotificationVideo';
import { useParams } from 'react-router-dom';

import YouTube from 'react-youtube';

const NotificationVideos = () => {
    const [ notification, setNotification ] = useState( [] );
    const [ video, setVideo ] = useState( {} )
    const id = useParams();

    // Get all Notification Videos.........................
    useEffect( () => {
        window.scrollTo( 0, 0, );
        fetch( 'https://api.newstube.app/apiv3/api/notification.php' )
            .then( res => res.json() )
            .then( data => {
                // console.log( data );
                const video = data?.body.filter( video => video?.nt_ytid === id?.id );

                setVideo( video[ 0 ] )
                setNotification( data?.body )
            } )
    }, [ id?.id ] )

    const opts = {
        height: '500',
        width: '100%',
        playerVars: {
            autoplay: 1,
        },
    };

    //     <div className='mt-5 px-[175px]'>
    //     <YouTube
    //         videoId={ videos[ 0 ].nt_ytid }
    //         opts={ opts } />
    //     <h1 className='text-xl font-black text-white my-4'>{ videos[ 0 ].nt_videotitle }</h1>
    // </div>

    return (
        <div className='w-11/12 mx-auto mt-16'>
            <h1 className='text-4xl font-bold pt-5'>All Notification Videos </h1>
            <hr className='my-10' />
            {
                notification?.length &&
                // <YouTube
                //     videoId={ id?.id }
                //     opts={ opts } />
                <div className='mt-5 px-[175px]'>
                    <YouTube
                        videoId={ id?.id }
                        opts={ opts } />
                    <h1 className='text-xl font-black text-white my-4'>{ video.nt_videotitle }</h1>
                </div>
            }
            <h1 className='text-4xl font-bold pt-5'>All Notification Videos </h1>
            <hr className='my-5' />
            {
                notification?.length ?
                    <Splide
                        options={ {
                            type: 'loop',
                            autoplay: true,
                            perPage: 4,
                            speed: 2500,
                            // interval: 3000,
                            perMove: 1,
                            height: '170px',
                            pagination: false,
                            gap: '20px',
                            // focus: 'center'
                        } }>
                        {
                            notification?.map( ( video, i ) =>
                                <SplideSlide key={ i } className='splide__slide2'>

                                    <SingleNotificationVideo video={ video }></SingleNotificationVideo>

                                    {/* <SingleCard video={ video }></SingleCard> */ }

                                </SplideSlide>


                            )
                        }

                    </Splide>
                    :
                    <></>
            }


        </div>
    );
};

export default NotificationVideos;