import React, { useState } from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import { useEffect } from 'react';
import SingleCard from '../../Shared/SingleCard/SingleCard';


const MusicVideo = () => {
    const [ Videos, setVideos ] = useState( [] );


    const url = 'https://api.newstube.app/apiv3/api/music.php'
    useEffect( () => {
        fetch( url )
            .then( res => res.json() )
            .then( data => {
                // console.log( data.body );
                setVideos( data?.body )
            } )
    }, [] )


    return (
        <div className='w-11/12 mx-auto'>
            <hr className='mt-32 mb-5' />
            <div className='px-2'>
                <h1 className='text-4xl font-semibold text-white mb-5'>Music News</h1>
                <Splide
                    options={ {
                        type: 'loop',
                        autoplay: true,
                        perPage: 4,
                        breakpoints: {
                            1024: {
                                perPage: 3,
                                height: '170px',
                                gap: '10px'

                            },
                            768: {
                                perPage: 2,
                                height: '190px',
                            },
                            640: {
                                // perPage: 1,
                                height: '150px',
                                gap: '10px'
                            }
                        },
                        speed: 3500,
                        // interval: 2500,
                        perMove: 3,
                        height: '170px',
                        pagination: false,
                        gap: '20px'
                    } }>
                    {
                        Videos?.map( ( video, i ) =>
                            <SplideSlide key={ i } >
                                <SingleCard video={ video }></SingleCard>
                                {/* <div className='relative cursor-pointer'>
                                <Link to={ `/videoplay/${ video?.nt_categoryname }/${ video?.nt_ytid }` }>
                                    <img
                                        // onMouseEnter={ () => handleVideo( video?.nt_videoid ) }
                                        // onMouseOut={ () => setShowIcon( false ) }
                                        src={ `${ video?.nt_videothumbnail }` } alt="Slide 1"
                                        className='relative w-full h-64 object-cover rounded-md transition ease-in-out delay-150 hover:-translate-y-10 hover:scale-100 hover:gap-5 duration-500 cursor-pointer' />
                                    {/* {
                                        showIcon && <div>
                                            {
                                                videoId === video?.nt_videoid && <div className='absolute bottom-2 left-2 duration-700'> { icon }</div>
                                            }
                                        </div>
                                    } */}

                                {/* </Link>
                                <p className='text-white absolute z-10 text-center cursor-text'>{ video?.nt_videotitle.length > 50 ? video?.nt_videotitle.slice( 0, 50 ) + ' .....' : video?.nt_videotitle }</p>
                            </div> */}
                            </SplideSlide>

                        )
                    }

                </Splide>
            </div>
        </div>
    );
};

export default MusicVideo;