import React, { useState } from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import { useEffect } from 'react';
import SingleCard from '../../Shared/SingleCard/SingleCard';


const SouthAsianNews = () => {
    const [ videos, setVideos ] = useState( [] );


    const url = 'https://api.newstube.app/apiv3/api/southasiannews.php'
    useEffect( () => {
        fetch( url )
            .then( res => res.json() )
            .then( data => {
                // console.log( data.body );
                setVideos( data?.body )
            } )
    }, [] )


    return (
        <div className='w-11/12 mx-auto'>
            <hr className='mt-32 mb-5' />
            <div className='px-2'>
                <h1 className='text-4xl font-semibold text-white mb-5'>South Asian News</h1>
                <Splide
                    options={ {
                        type: 'loop',
                        autoplay: true,
                        perPage: 4,
                        breakpoints: {
                            1024: {
                                perPage: 3,
                                height: '170px',
                                gap: '10px'

                            },
                            768: {
                                perPage: 2,
                                height: '190px',
                            },
                            640: {
                                // perPage: 1,
                                height: '150px',
                                gap: '10px'
                            }
                        },
                        speed: 3500,
                        // interval: 2500,
                        perMove: 3,
                        height: '170px',
                        pagination: false,
                        gap: '20px'
                    } }>
                    {
                        videos?.map( ( video, i ) =>
                            <SplideSlide key={ i } >
                                <SingleCard video={ video }></SingleCard>

                            </SplideSlide>

                        )
                    }

                </Splide>
            </div>
        </div>
    );
};

export default SouthAsianNews;