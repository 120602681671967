import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CommonPage from '../../../Components/CommonPage/CommonPage';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import SingleCard from '../../../Shared/SingleCard/SingleCard';




const Category = () => {
    const name = useParams();
    const [ videos, setVideos ] = useState( [] )

    useEffect( () => {
        fetch( 'https://api.newstube.app/apiv2/api/read.php' )
            .then( res => res.json() )
            .then( data => {
                // console.log( data );
                const videos = data.body.filter( video => video?.nt_categoryname === name?.name );
                setVideos( videos )
                // console.log( videos );
                window.scrollTo( 0, 0 )
            } )
    }, [ name?.name ] )


    return (
        <div className='mt-16 pt-3 w-11/12 mx-auto '>
            <h1 className='text-4xl font-bold text-white'>{ name?.name }</h1>
            <hr className='my-5' />
            {
                videos?.length ?
                    <CommonPage videos={ videos }></CommonPage>
                    :
                    <h1 className='text-5xl font-bold text-center'>No Videos Found !!!!</h1>
            }
            <h1 className='text-4xl font-bold text-white mt-28 mb-5'>{ name?.name }</h1>
            <hr className='my-10' />
            {
                videos?.length ?
                    <Splide
                        options={ {
                            type: 'loop',
                            autoplay: true,
                            perPage: 3,
                            speed: 2500,
                            // interval: 3000,
                            perMove: 1,
                            height: '250px',
                            pagination: false,
                            gap: '20px',
                            // focus: 'center'
                        } }>
                        {
                            videos?.map( ( video, i ) =>
                                <SplideSlide key={ i } className='splide__slide2'>

                                    <SingleCard video={ video }></SingleCard>

                                    {/* <Link to={ `/videoplay/${ video?.nt_categoryname }/${ video?.nt_ytid }` } className=" rounded-lg cursor-pointer">
                                        <div className="card bg-base-300 image-full transition ease-in-out delay-150 hover:-translate-y-5 hover:scale-100 duration-500 cursor-pointer">
                                            <figure><img src={ `${ video?.nt_videothumbnail }` } alt="news" /></figure>
                                            <div className="card-body">
                                                <h2 className="card-title text-orange-200">{ video?.nt_videotitle }</h2>
                                            </div>
                                        </div>
                                    </Link> */}
                                </SplideSlide>

                                // <SplideSlide key={ i } >
                                //     <Link to={ `/videoplay/${ video?.nt_categoryname }/${ video?.nt_ytid }` } className="hero h-full rounded-lg  cursor-pointer" style={ { backgroundImage: `url(${ video?.nt_videothumbnail })` } }>
                                //         <div className="hero-overlay bg-opacity-30"></div>
                                //         <div className="hero-content text-center text-neutral-content">
                                //             <div className="max-w-md">
                                //                 {/* <h1 className="mb-5 text-2xl font-bold">{ video?.nt_videotitle }</h1> */ }
                                //                 {/* <button className="btn btn-primary">Get Started</button> */ }
                                //             </div>
                                //         </div>
                                //     </Link>
                                // </SplideSlide>

                            )
                        }

                    </Splide>
                    :
                    <></>
            }



        </div>
    );
};

export default Category;