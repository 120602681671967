import React from 'react';
import { useEffect } from 'react';

const TermsConditions = () => {

    useEffect( () => {
        // window.scrollTo( 0, 0 )
    }, [] )

    return (
        <div className='w-11/12 mx-auto my-16'>
            <h1 className='text-3xl font-bold py-5 text-center underline'>Terms & Conditions</h1>
            <p className='text-justify'>A Terms and Conditions agreement outlines the terms that visitors must agree to if they want to interact with your website. Essentially, if the visitor continues to use the website after accepting the Terms, they enter into a contract with you. Unlike a Privacy Policy, you aren't legally required to have a Terms and Conditions agreement. However, there are many reasons why you should draft one and display it on your website. We'll discuss those reasons and show you a few of the most important clauses your Terms and Conditions should include. Note that you'll sometimes see this agreement referred to as a Terms of Use, User Agreement or Terms of Service agreement. These terms are interchangeable and refer to the same type of agreement. The purpose of a Terms and Conditions agreement is to prevent misunderstandings between the business owner (you), and the consumer. The agreement helps you: Protect your intellectual property Avoid website abuse Define the limits of your legal obligations to the consumer Essentially, the T&C helps you run your business more effectively and with greater peace of mind. This agreement forms the basis of an enforceable legal relationship. It tells anyone browsing your website, whether they are a casual visitor or an active client, what their legal responsibilities and rights are. It also gives you, as the business owner and service provider, authority over certain undesirable things that a consumer may do on your website. However, let's consider the specific reasons why business owners should always include a Terms and Conditions agreement on their website.</p>
        </div>
    );
};

export default TermsConditions;