import React, { useState, useEffect } from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import SingleCard from '../../Shared/SingleCard/SingleCard';

const BangladeshPolitics = () => {
    const [ Videos, setVideos ] = useState( [] );
    // const [ icon, setIcon ] = useState( {} );
    // const [ videoId, setVideoId ] = useState()


    const url = 'https://api.newstube.app/apiv3/api/bangladeshpolitics.php'
    useEffect( () => {
        fetch( url )
            .then( res => res.json() )
            .then( data => {
                // console.log( data.body );
                setVideos( data?.body )
            } )
    }, [] )


    return (
        <div className='w-11/12 mx-auto'>
            <hr className='mt-32 mb-5' />
            <div className='px-2'>
                <h1 className='text-4xl font-semibold text-white mb-5'>Bangladesh Politics News</h1>
                <Splide
                    options={ {
                        type: 'loop',
                        autoplay: true,
                        perPage: 4,
                        breakpoints: {
                            1024: {
                                perPage: 3,
                                height: '170px',
                                gap: '10px'

                            },
                            768: {
                                perPage: 2,
                                height: '190px',
                            },
                            640: {
                                // perPage: 1,
                                height: '150px',
                                gap: '10px'
                            }
                        },
                        speed: 3000,
                        // interval: 3000,
                        perMove: 1,
                        height: '170px',
                        pagination: false,
                        gap: '10px'
                    } }>
                    {
                        Videos?.map( ( video, i ) =>
                            <SplideSlide key={ i } >

                                <SingleCard video={ video }></SingleCard>


                                {/* <Link to={ `/videoplay/${ video?.nt_categoryname }/${ video?.nt_ytid }` } className="hero h-full rounded-lg transition ease-in-out delay-150 hover:-translate-y-5 hover:scale-100 duration-500 cursor-pointer" style={ { backgroundImage: `url(${ video?.nt_videothumbnail })` } }>
                                <div className="hero-overlay bg-opacity-30"></div>
                                <div className="hero-content text-center text-neutral-content">
                                 </div>
                            </Link> */}

                            </SplideSlide>

                        )
                    }

                </Splide>
            </div>
        </div>
    );
};

export default BangladeshPolitics;