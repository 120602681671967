import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// import logoDark from '../../Assets/Logo/Logo-black.png'
import logoLight from '../../Assets/Logo/Logo-white.png'
import { FaBell, FaCaretDown, FaRegUser, FaSearch } from "react-icons/fa";
import { HiOutlineUserAdd } from "react-icons/hi";
import { BiLogIn } from "react-icons/bi";

import './Navbar.css'

const Navbar = () => {
    const [ categories, setCategories ] = useState( [] );
    const [ notification, setNotification ] = useState( [] );



    // Get all Categories.....................
    useEffect( () => {
        fetch( 'https://api.newstube.app/apiv2/api/cat.php' )
            .then( res => res.json() )
            .then( data => {
                // console.log( data );
                // setCategories( data?.body.slice( 0, 12 ) )
                setCategories( data?.body )
            } )
    }, [] )


    // Get all Notification Videos.........................
    useEffect( () => {
        fetch( 'https://api.newstube.app/apiv3/api/notification.php' )
            .then( res => res.json() )
            .then( data => {
                // console.log( data );

                setNotification( data?.body )
            } )
    }, [] )

    return (

        <div className="navbar bg-[#181616ed] shadow-lg p-0  fixed top-0 z-50">
            <div className='w-11/12 mx-auto flex justify-between items-center'>
                <div className="flex gap-5">
                    <Link to='/' className="text-xl">
                        <img className='w-40' src={ logoLight } alt="" />
                    </Link>
                    <div className="flex items-center gap-5 px-1 font-bold ">
                        {/* <ul className="flex gap-5 px-1 font-bold"> */ }
                        {/* <ul className="menu menu-horizontal px-1 font-bold"> */ }
                        <Link to='/' className='hover:text-red-700 duration-700'>Home</Link>
                        <Link to='/livepage' className='hover:text-red-700 duration-700'>Live</Link>
                        <Link to='/trending' className='hover:text-red-700 duration-700'>Trending</Link>
                        <div className="dropdown dropdown-hover">
                            <label tabIndex={ 0 } className='flex gap-1 items-center cursor-pointer hover:text-red-700 duration-700'>
                                Categories
                                <FaCaretDown />
                            </label>
                            <div tabIndex={ 0 } className="dropdown-content menu p-5 shadow bg-[#1a1717f6] rounded-md w-60 gap-1 font-normal overflow-visible">
                                {
                                    categories?.length > 11 ? categories.slice( 0, 11 ).map( ( category, i ) =>

                                        <button key={ i } className="button text-left px-2 h-10">
                                            <Link to={ `/categories/${ category?.nt_categoryname }` } className="button-content">{ category?.nt_categoryname }</Link>
                                        </button>

                                    )

                                        : categories?.map( ( category, i ) =>

                                            <button key={ i } className="button text-left px-2 h-10">
                                                {/* <span className="button-content ">{ category?.nt_categoryname }</span> */ }
                                                <Link to={ `/categories/${ category?.nt_categoryname }` } className="button-content">{ category?.nt_categoryname }</Link>
                                            </button>
                                        )
                                }
                                <Link to='allcategories' className="button text-left px-2 h-10 bg-[#121111] flex items-center ">
                                    <span className="button-content">See All ...</span>
                                </Link>

                            </div>
                        </div>
                        {/* </ul> */ }
                    </div>
                </div>
                <div className='flex items-center gap-2'>
                    {/* <div className='flex gap-2 items-center rounded-md bg-[#121111] px-3 py-2'>
                        <FaSearch />
                        {/* <div className="divider m-0 h-6 border border-gray-400"></div> */ }
                    {/* <input type="text" placeholder='Search' className='focus:outline-none bg-transparent' />
                    </div> */}

                    <div className="input-container hover:text-red-700 cursor-pointer duration-300">
                        <input placeholder="Search" className="input focus:bg-[#121111]" type="text" />
                        <FaSearch className='icon text-lg ' />
                    </div>

                    <div>
                        <div className="dropdown dropdown-end">
                            <label tabIndex={ 0 } className="btn bg-[#121111] btn-circle">
                                <FaRegUser className='text-xl cursor-pointer hover:text-red-600 duration-500' />
                            </label>
                            <div tabIndex={ 0 } className="mt-3 card card-compact dropdown-content bg-[#211e1ee2] w-64 rounded-md shadow">
                                <div className="card-body ">
                                    <Link to='/register' className='text-lg flex gap-2 items-center hover:bg-[#1c1818fe] duration-500 px-2 py-1 rounded-sm'>
                                        {/* <SlUserFollow /> */ }
                                        <HiOutlineUserAdd />
                                        Register
                                    </Link>
                                    <hr className='opacity-30' />
                                    <Link to='/login' className='text-lg flex gap-2 items-center hover:bg-[#1c1818fe] duration-500 px-2 py-1 rounded-sm'>
                                        <BiLogIn />
                                        Login
                                    </Link>
                                </div>
                            </div>
                        </div>

                    </div>


                    <Link to={ `/notification/${ notification[ 0 ]?.nt_ytid }` } className="indicator cursor-pointer">
                        <span className="indicator-item badge bg-red-600 text-white">{ notification?.length }</span>
                        <FaBell className='text-xl' />
                    </Link>

                    {/* Drawer------------------ */ }

                </div>
            </div>
        </div>

        // <div className="drawer drawer-end">
        //     <input id="my-drawer-4" type="checkbox" className="drawer-toggle" />
        //     <div className="drawer-content">
        //         {/* <!-- Page content here --> */ }
        //         <div className="navbar bg-[#181616f7] shadow-lg p-0 text-[#CFCECD]">
        //             <div className='w-11/12 mx-auto flex justify-between items-center'>
        //                 <div className="flex gap-5">
        //                     <Link to='/' className="text-xl">
        //                         <img className='w-40' src={ logoLight } alt="" />
        //                     </Link>
        //                     <div className="flex items-center gap-5 px-1 font-bold text-">
        //                         {/* <ul className="flex gap-5 px-1 font-bold"> */ }
        //                         {/* <ul className="menu menu-horizontal px-1 font-bold"> */ }
        //                         <Link className='hover:text-red-700 duration-700'>Home</Link>
        //                         <Link className='hover:text-red-700 duration-700'>Live</Link>
        //                         <Link className='hover:text-red-700 duration-700'>Trending</Link>
        //                         <div className="dropdown dropdown-hover">
        //                             <label tabIndex={ 0 } className='flex gap-1 items-center cursor-pointer hover:text-red-700 duration-700'>
        //                                 Categories
        //                                 <FaCaretDown />
        //                             </label>
        //                             <div tabIndex={ 0 } className="dropdown-content menu p-5 shadow bg-[#181616] rounded-md w-60 gap-2 font-normal">
        //                                 {
        //                                     categories?.map( ( category, i ) => <div key={ i } className='border-l-4 hover:bg-gradient-to-r from-red-700 border-transparent hover:border-red-600 transition duration-500 rounded-none pl-2 py-2'><Link >{ category?.nt_categoryname }</Link></div> )
        //                                 }

        //                             </div>
        //                         </div>
        //                         {/* </ul> */ }
        //                     </div>
        //                 </div>
        //                 <div className='flex items-center gap-2'>
        //                     <div className='flex gap-2 items-center rounded-md bg-[#121111] px-3 py-2'>
        //                         <FaSearch />
        //                         {/* <div className="divider m-0 h-6 border border-gray-400"></div> */ }
        //                         <input type="text" placeholder='Search' className='focus:outline-none bg-transparent' />
        //                     </div>
        //                     <div className='p-3 bg-[#121111] rounded-full'>
        //                         <FaRegUser className='text-xl' />
        //                     </div>


        //                     <label htmlFor="my-drawer-4" className="drawer-button btn bg-transparent border-none p-0">
        //                         <div className="indicator">
        //                             <span className="indicator-item badge bg-red-600 text-white">{ notification?.length }</span>
        //                             <FaBell className='text-xl' />
        //                         </div>
        //                     </label>

        //                     {/* Drawer------------------ */ }

        //                 </div>
        //             </div>
        //         </div>

        //     </div>
        //     <div className="drawer-side">
        //         <label htmlFor="my-drawer-4" className="drawer-overlay"></label>
        //         <ul className="menu p-4 w-80 bg-[#181616f2] text-base-content">
        //             {/* <!-- Sidebar content here --> */ }

        //             {
        //                 notification?.map( ( video, i ) => <li key={ i }><a>Sidebar Item { i + 1 }</a></li> )
        //             }

        //             {/* {
        //                 notification?.map( ( video, i ) => <li key={ i }><Link><VideoPlayer video={ video }></VideoPlayer></Link></li> )
        //             } */}

        //         </ul>
        //     </div>
        // </div>


    );
};

export default Navbar;


