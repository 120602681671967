import React, { useState } from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import { useEffect } from 'react';
import SingleCard from '../../Shared/SingleCard/SingleCard';




const LatestUpload = () => {
    const [ latestVideos, setLatestVideos ] = useState( [] );


    const url = 'https://api.newstube.app/apiv3/api/latestnews.php'
    useEffect( () => {

        window.scrollTo( 0, 0 )
        fetch( url )
            .then( res => res.json() )
            .then( data => {
                // console.log( data.body );
                setLatestVideos( data?.body )
            } )
    }, [] )



    return (
        <div className='w-11/12 mx-auto'>
            <hr className='mt-32 mb-5' />
            <div className='px-2'>
                <h1 className='text-4xl font-semibold text-white mb-10'>Latest Upload</h1>
                <Splide
                    options={ {
                        type: 'loop',
                        autoplay: true,
                        perPage: 4,
                        breakpoints: {
                            1024: {
                                perPage: 3,
                                height: '170px',
                                gap: '10px'

                            },
                            768: {
                                perPage: 2,
                                height: '190px',
                            },
                            640: {
                                // perPage: 1,
                                height: '150px',
                                gap: '10px'
                            }
                        },
                        speed: 2000,
                        perMove: 1,
                        height: '170px',
                        pagination: false,
                        gap: '10px'
                    } }
                // class="splide splide-sm lg:splide-lg xl:splide-xl"
                >
                    {
                        latestVideos?.map( ( video, i ) =>
                            <SplideSlide key={ i } >
                                <SingleCard video={ video } i={ i }></SingleCard>

                                {/* <h1 className='z-50'>{ video?.nt_videotitle }</h1> */ }



                                {/* <div className='relative cursor-pointer h-full'>
                                    <SingleCard video={ video } i={ i }></SingleCard> 


                                  

                                    <img src={ `${ video?.nt_videothumbnail }` } alt="Slide 1" className='w-full h-72 object-cover rounded-md ' />

                                    <Link to={ `/videoplay/${ video?.nt_categoryname }/${ video?.nt_ytid }` } className='w-full text-right h-full z-10 absolute top-0 transition ease-in-out delay-150 hover:-translate-y-10 hover:scale-125 duration-500 cursor-pointer'>
                                        <span className='text-8xl font-bold absolute right-0 bottom-2 text-white '>{ i + 1 }</span>
                                    </Link>

                                </div> */}

                            </SplideSlide>

                        )
                    }

                </Splide>
            </div>
        </div>
    );
};

export default LatestUpload;