import React, { useEffect, useState } from 'react';
import newsTube from '../../Assets/Logo/Logo-white.png'
import rajnity from '../../Assets/Logo/rajnitywhite.png'
import { Link } from 'react-router-dom';
import { GrMail } from "react-icons/gr";
import profile from '../../Assets/Logo/kanaksarwarNews.png'
import { FaCaretUp, FaFacebook, FaGoogle, FaInstagram, FaTwitter } from 'react-icons/fa';



const Footer = () => {
    const [ categories, setCategories ] = useState( [] );


    // Get all Categories.....................
    useEffect( () => {
        fetch( 'https://api.newstube.app/apiv2/api/cat.php' )
            .then( res => res.json() )
            .then( data => {
                // console.log( data );
                setCategories( data?.body )
            } )
    }, [] )



    return (
        <div>
            <hr className='w-11/12 mx-auto mt-32' />
            <footer className="grid grid-cols-1 lg:grid-cols-4 gap-5 p-10 text-base-content">

                {/* Logo section------------- */ }
                <div className='flex flex-col gap-8 items-center justify-center h-full'>
                    <Link to='/'>
                        <img src={ newsTube } alt="" className='w-1/2 lg:w-3/4 mx-auto' />
                    </Link>

                    <Link to='https://rajnity.com/' target='_blank'>
                        <img src={ rajnity } alt="" className='w-1/2 lg:w-3/4 mx-auto' />
                    </Link>
                </div>

                {/* Explore Section----------------- */ }
                <hr className='lg:hidden w-4/5 mx-auto border-gray-500' />
                <div className='text-white font-medium flex flex-col lg:text-start text-center gap-2 lg:gap-4 text-lg'>

                    <span className="text-xl ">EXPLORE</span>
                    <span><Link to='/' className="hover:text-red-600 duration-700 w-fit">Home</Link></span>
                    <span><Link to='/livepage' className="hover:text-red-600 duration-700 w-fit">Live</Link></span>
                    <span><Link to='/trending' className="hover:text-red-600 duration-700 w-fit">Trending</Link></span>
                    <span><Link to='/privacypolicy' className="hover:text-red-600 duration-700 w-fit">Privacy Policy</Link></span>
                    <span><Link to='/aboutus' className="hover:text-red-600 duration-700 w-fit">About Us</Link></span>
                    <span><Link to='/terms&conditions' className="hover:text-red-600 duration-700 w-fit">Terms & Conditions</Link></span>


                    <div className="dropdown dropdown-top">
                        <label tabIndex={ 0 } className="text-gray-400 py-2 w-1/3 lg:w-1/2 mx-auto lg:mx-0 px-4 m-1 rounded-lg flex gap-2 bg-transparent border border-gray-400 items-center cursor-pointer hover:text-red-600 duration-700">
                            Categories
                            <FaCaretUp />
                        </label>
                        <div tabIndex={ 0 } className="dropdown-content text-base menu p-5 shadow bg-[#1a1717] rounded-md w-60 gap-2 font-normal overflow-visible ">
                            {
                                categories?.map( ( category, i ) =>

                                    <button key={ i } className="button text-left px-2 h-10">
                                        {/* <span className="button-content ">{ category?.nt_categoryname }</span> */ }
                                        <Link to={ `/categories/${ category?.nt_categoryname }` } className="button-content">{ category?.nt_categoryname }</Link>
                                    </button>

                                )
                            }

                        </div>
                    </div>


                </div>

                {/* Contact Section----------------- */ }

                <hr className='lg:hidden w-4/5 mx-auto border-gray-500' />
                <div className='text-white font-medium flex flex-col justify-center lg:justify-start lg:items-start items-center gap-2 lg:gap-4 text-lg'>
                    <span className="text-xl">CONTACT</span>

                    <Link to='mailto:info@newstube.app' className="flex gap-2 items-center">
                        <GrMail className='text-lg text-red-600' />
                        <p>info@newstube.app</p>
                    </Link>

                    <Link to="mailto:kanaksarwar@gmail.com" className="flex gap-2 items-center">
                        <GrMail className='text-lg text-red-600' />
                        <p>kanaksarwar@gmail.com</p>
                    </Link>

                </div>

                {/* Publisher Details------------ */ }
                <hr className='lg:hidden w-4/5 mx-auto border-gray-500' />
                <div className='text-white font-medium flex flex-col gap-5 text-lg'>
                    <img src={ profile } alt="" className='w-1/3 mx-auto' />
                    <p className='text-red-600 text-center'>Editor & Publisher: <span className='text-white'> Dr.kanak Sarwar</span></p>
                    <div className='flex justify-between px-10'>
                        <Link target='_blank' to='https://www.facebook.com/drkanaksarwarbd/'>
                            <FaFacebook className='cursor-pointer text-4xl lg:text-2xl hover:text-blue-600 duration-700' />
                        </Link>
                        <Link target='_blank' to='https://twitter.com/kanaksarwar'>
                            <FaTwitter className='cursor-pointer text-4xl lg:text-2xl hover:text-sky-700 duration-700' />
                        </Link>
                        <Link target='_blank' to=''>
                            <FaGoogle className='cursor-pointer text-4xl lg:text-2xl hover:text-red-600 duration-700' />
                        </Link>
                        <Link target='_blank' to='https://www.instagram.com/kanaksarwar/'>
                            <FaInstagram className='cursor-pointer text-4xl lg:text-2xl hover:text-red-500 duration-700' />
                        </Link>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Footer;