import React, { useState } from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import { useEffect } from 'react';
import SingleCard from '../../Shared/SingleCard/SingleCard';

const NewsReports = () => {
    const [ videos, setVideos ] = useState( [] );


    const url = 'https://api.newstube.app/apiv3/api/newsreport.php'
    useEffect( () => {
        fetch( url )
            .then( res => res.json() )
            .then( data => {
                setVideos( data?.body )
            } )
    }, [] )


    return (
        <>
            {
                videos?.length &&
                <div className='w-11/12 mx-auto'>
                    <hr className='mt-32 mb-5' />
                    <div className='px-2'>
                        <h1 className='text-4xl font-semibold text-white mb-5'>News Reports</h1>
                        <Splide
                            options={ {
                                type: 'loop',
                                autoplay: true,
                                perPage: 4,
                                speed: 3500,
                                // interval: 2500,
                                perMove: 3,
                                height: '170px',
                                pagination: false,
                                gap: '20px'
                            } }>
                            {
                                videos?.map( ( video, i ) =>
                                    <SplideSlide key={ i } >
                                        <SingleCard video={ video }></SingleCard>

                                    </SplideSlide>

                                )
                            }

                        </Splide>
                    </div>
                </div>
            }
        </>
    );
};

export default NewsReports;