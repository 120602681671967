import React, { useState, useEffect } from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import { Link } from 'react-router-dom';
import SingleCard from '../../Shared/SingleCard/SingleCard';

const Entertainment = () => {
    const [ entertainmentVideo, setEntertainmentVideos ] = useState( [] );


    const url = 'https://api.newstube.app/apiv3/api/entertainment.php'
    useEffect( () => {
        fetch( url )
            .then( res => res.json() )
            .then( data => {
                // console.log( data.body );
                setEntertainmentVideos( data?.body )
            } )
    }, [] )
    return (
        <div className='w-11/12 mx-auto'>
            <hr className='mt-32 mb-5' />
            <div className='px-2'>
                <h1 className='text-4xl font-semibold text-white mb-5'>Entertainment News</h1>
                <Splide
                    options={ {
                        type: 'loop',
                        autoplay: true,
                        perPage: 3,
                        breakpoints: {
                            1024: {
                                perPage: 3,
                                height: '170px',
                                gap: '10px'

                            },
                            768: {
                                perPage: 2,
                                height: '190px',
                            },
                            640: {
                                // perPage: 1,
                                height: '150px',
                                gap: '10px'
                            }
                        },
                        speed: 2000,
                        perMove: 1,
                        height: '220px',
                        // width: '100%',
                        pagination: true,
                        gap: '10px',

                    } }>
                    {
                        entertainmentVideo?.map( ( video, i ) =>
                            <SplideSlide key={ i } >
                                <SingleCard video={ video }></SingleCard>

                                {/* <Link to={ `/videoplay/${ video?.nt_categoryname }/${ video?.nt_ytid }` } className="mb-5 rounded-lg cursor-pointer">
                                    <div className="card bg-base-300 image-full cursor-pointer">
                                        <figure><img src={ `${ video?.nt_videothumbnail }` } alt="news" /></figure>
                                        <div className="card-body">
                                            <h2 className="card-title text-orange-200">{ video?.nt_videotitle }</h2>
                                        </div>
                                    </div>
                                </Link> */}
                            </SplideSlide>

                        )
                    }

                </Splide>
            </div>
        </div>
    );
};

export default Entertainment;