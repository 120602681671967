import React from 'react';
import YouTube from 'react-youtube';

const CommonPage = ( { videos } ) => {

    const opts = {
        height: '500',
        width: '100%',
        playerVars: {
            autoplay: 1,
        },
    };

    // console.log( videos );
    return (
        <div className='mt-5 px-[175px]'>
            <YouTube
                videoId={ videos[ 0 ].nt_ytid }
                opts={ opts } />
            {/* <h1>{videos[ 0 ].nt_ytid}</h1> */ }
            <h1 className='text-xl font-black text-white my-4'>{ videos[ 0 ].nt_videotitle }</h1>
        </div>
    );
};

export default CommonPage;