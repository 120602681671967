import React, { useRef, useState } from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaPlay } from 'react-icons/fa';
import './BusinessNews.css'
import SingleCard from '../../Shared/SingleCard/SingleCard';


const BusinessNews = () => {
    const [ businessVideos, setBusinessVideos ] = useState( [] );
    // const [ icon, setIcon ] = useState( false );
    const [ bgImage, setBgImage ] = useState( '' );
    const [ video, setVideo ] = useState( [] );
    const splideRef = useRef( null );
    const [ title, setTitle ] = useState( '' )


    const url = 'https://api.newstube.app/apiv3/api/business.php'
    useEffect( () => {
        fetch( url )
            .then( res => res.json() )
            .then( data => {
                // console.log( data.body[0].nt_videothumbnail );

                setBgImage( data.body[ 0 ].nt_videothumbnail )
                setBusinessVideos( data?.body );

                setVideo( [ data.body[ 0 ].nt_ytid, data?.body[ 0 ].nt_categoryname ] );

                setTitle( data?.body[ 0 ].nt_videotitle )
            } )
    }, [] )

    useEffect( () => {
        const splide = splideRef.current.splide;

        // Listen for the "moved" event to get the active slide element
        splide.on( 'moved', () => {
            const activeSlideElement = splide.Components.Elements.slides[ splide.index ];

            // Add your custom logic here with the active slide element
            const activeImage = activeSlideElement.querySelector( 'img' );
            const Image = activeImage.src;
            setBgImage( Image )

            const activeLink = activeSlideElement.querySelector( 'a' );
            // const videoId = ( activeLink.href.split( '/' ) )[ ( ( activeLink.href.split( '/' ) ) ).length - 1 ];
            const videoId = ( activeLink.href.split( '/' ) );
            setVideo( videoId );
            // console.log( videoId.slice( -2 ) );

            const activeTitle = activeSlideElement.querySelector( 'p' );
            setTitle( activeTitle.innerText )
        } )
    } );



    return (
        <div className='relative'>
            <hr className='w-11/12 mt-32 mb-5 mx-auto' />
            <h1 className='w-11/12 mx-auto text-4xl font-semibold text-white mb-5'>Business News</h1>

            <div style={ { backgroundImage: `url(${ bgImage })` } } className='hero cursor-pointer h-[350px] md:h-[450px] lg:h-[600px] py-7 place-items-start px-20'></div>

            <div className='bgoverlay h-[350px] md:h-[450px] lg:h-[600px]'>

                <div className='bg-[#181616f3] p-5 rounded-lg absolute top-8 right-10 lg:right-16 w-[200px] md:w-[250px] lg:w-[350px] h-[300px] md:h-[400px] lg:h-[550px] py-6'>

                    <Splide
                        ref={ splideRef }
                        options={ {
                            type: 'loop',
                            autoplay: true,
                            perPage: 3,
                            breakpoints: {
                                1024: {
                                    perPage: 2,
                                    height: '350px',
                                    // gap: '10px',
                                },
                                768: {
                                    perPage: 2,
                                    height: '350px',
                                },
                                640: {
                                    height: '250px',
                                    gap: '5px'
                                }
                            },
                            direction: 'ttb',
                            speed: 2000,
                            perMove: 1,
                            height: '500px',
                            width: '350px',
                            pagination: false,
                            gap: '10px',
                            focus: 'center'
                        } }>
                        {
                            businessVideos?.map( ( video, i ) =>
                                <SplideSlide key={ i } className='splide__slide1 '>
                                    <SingleCard video={ video }></SingleCard>

                                    <p className='text-orange-500 hidden absolute z-20 top-0 text-center text-sm px-10'>{ video?.nt_videotitle }</p>

                                </SplideSlide>

                            )
                        }

                    </Splide>

                </div>


                <Link to={ `/videoplay/${ video[ 1 ] }/${ video[ 0 ] }` } className='absolute top-2/3 left-10 lg:left-32'>

                    <button className='headerbtn border-none mt-3 flex justify-center rounded-sm btn md:btn-wide  items-center gap-2 text-white bg-[#E50914] hover:bg-red-700 text-center px-7 py-5 '>
                        <FaPlay />
                        <span className='font-bold'>Play Now</span>
                    </button>

                </Link>
                <Link to={ `/videoplay/${ video[ 1 ] }/${ video[ 0 ] }` }>
                    <p className='w-1/2 mx-auto absolute left-10 lg:left-20 top-1/4 text-white hover:text-[#E50914] duration-700 text-lg md:text-xl font-medium md:font-semibold lg:font-bold cursor-pointer'>{ title }</p>
                </Link>

            </div>
        </div>
    );
};

export default BusinessNews;