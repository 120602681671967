import React, { useEffect, useState } from "react";



import { TfiAngleRight } from "react-icons/tfi";
import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';


import './Header.css'
import HeaderBanner from "../../Shared/HeaderBanner/HeaderBanner";

const Header = () => {
    const [ liveShows, setLiveShows ] = useState( [] );


    useEffect( () => {
        fetch( 'https://api.newstube.app/apiv3/api/liveshow.php' )
            .then( res => res.json() )
            .then( data => {
                // console.log( data.body );
                setLiveShows( data?.body );
                window.scrollTo( 0, 0 )
            } )
    }, [] );


    return (
        <div className="mt-16">
            <Splide
                hasTrack={ false }
                // aria-label="..."
                options={ {
                    type: 'loop',
                    rewind: true,
                    autoplay: true,
                    width: '100%',
                    speed: 1000,
                    pagination: false,
                    padding: '15rem',
                    breakpoints: {
                        1024: {
                            padding: '2rem',
                        },
                    },
                    gap: '10px'
                } }
            >
                <SplideTrack>
                    {
                        liveShows?.map( ( video, i ) =>
                            <SplideSlide key={ i } className="splide__slideHeader"
                            >

                                <HeaderBanner video={ video }></HeaderBanner>

                                {/* <iframe
                                    title="Embedded Video"
                                    width="100%"
                                    height="15"
                                    src={ `https://www.youtube.com/embed/${ video?.nt_ytid }` }
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe> */}

                            </SplideSlide>
                        )
                    }
                </SplideTrack>

                <div className="splide__arrows">
                    <button className="splide__arrow splide__arrow--prev arrow">
                        <TfiAngleRight className="text-3xl" />
                    </button>
                    <button className="splide__arrow splide__arrow--next arrow">
                        <TfiAngleRight className="text-3xl" />
                    </button>
                </div>
            </Splide>
        </div>

    );
};

export default Header;