import React from 'react';
import Header from '../Header/Header';
import LatestUpload from '../LatestUpload/LatestUpload';
import NewsTube from '../../Categories/NewsTube/NewsTube';
import BangladeshiMediaNews from '../../Categories/BangladeshiMediaNews/BangladeshiMediaNews';
import FacebookVideos from '../../Categories/BangladeshiMediaNews/BangladeshiMediaNews';
import InternationalTvNews from '../../Categories/InternationalTvNews/InternationalTvNews';
import Infotainment from '../../Categories/Infotainment/Infotainment';
import Entertainment from '../../Categories/Entertainment/Entertainment';
import Movie from '../../Categories/Movie/Movie';
import BusinessNews from '../../Categories/BusinessNews/BusinessNews';
import LifeStyleNews from '../../Categories/LifeStyleNews/LifeStyleNews';
import ViralVideos from '../../Categories/ViralVideos/ViralVideos';
import KanaksarwarNews from '../../Categories/KanaksarwarNews/KanaksarwarNews';
import Technology from '../../Categories/Technology/Technology';
import BangladeshPolitics from '../../Categories/BangladeshPolitics/BangladeshPolitics';

const Home = () => {



    return (
        <div className='pb-10'>
            <Header></Header>
            <LatestUpload></LatestUpload>
            {/* <FacebookVideos></FacebookVideos> */ }

            <BangladeshiMediaNews></BangladeshiMediaNews>
            <KanaksarwarNews></KanaksarwarNews>
            <NewsTube></NewsTube>
            <BangladeshPolitics></BangladeshPolitics>
            <InternationalTvNews></InternationalTvNews>
            <Infotainment></Infotainment>
            <Entertainment></Entertainment>
            <Movie></Movie>
            <Technology></Technology>
            <BusinessNews></BusinessNews>
            <LifeStyleNews></LifeStyleNews>
            <ViralVideos></ViralVideos>
        </div>
    );
};

export default Home;