import React, { useRef, useState } from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaPlay } from 'react-icons/fa';
import { TfiAngleRight } from 'react-icons/tfi';
import SingleCard from '../../Shared/SingleCard/SingleCard';

import './NewsTube.css'

const NewsTube = () => {
    const [ latestVideos, setLatestVideos ] = useState( [] );
    // const [ icon, setIcon ] = useState( false );
    const [ bgImage, setBgImage ] = useState( '' );
    const [ video, setVideo ] = useState( [] );
    const splideRef = useRef( null );


    const url = 'https://api.newstube.app/apiv3/api/kanaksarwarnews.php'
    useEffect( () => {
        fetch( url )
            .then( res => res.json() )
            .then( data => {
                // console.log( data.body[0].nt_videothumbnail );
                setBgImage( data.body[ 0 ].nt_videothumbnail )
                setLatestVideos( data?.body );
                setVideo( [ data.body[ 0 ].nt_ytid, data?.body[ 0 ].nt_categoryname ] );
            } )
    }, [] )

    useEffect( () => {
        const splide = splideRef.current.splide;

        // Listen for the "moved" event to get the active slide element
        splide.on( 'moved', () => {
            const activeSlideElement = splide.Components.Elements.slides[ splide.index ];

            const activeImage = activeSlideElement.querySelector( 'img' );
            const Image = activeImage.src;
            setBgImage( Image )

            const activeLink = activeSlideElement.querySelector( 'a' );
            const videoId = ( activeLink.href.split( '/' ) );
            // setVideo( videoId );
            setVideo( videoId.slice( -2 ) );
            // console.log( videoId.slice( -2 ) );
        } )
    } );


    return (
        <div className='relative'>
            <hr className='w-11/12 mt-32 mb-5 mx-auto' />
            <h1 className='w-11/12 mx-auto text-4xl font-semibold text-white mb-5 z-10'>News Tube</h1>

            <div style={ { backgroundImage: `url(${ bgImage })` } } className='hero cursor-pointer h-[350px] md:h-[450px] lg:h-[600px] py-7 place-items-start px-20'>
            </div>

            <div className='bgoverlay h-[350px] md:h-[450px] lg:h-[600px]'>

                <div className='bg-[#181616f3] p-5 rounded-lg absolute top-8 left-10 lg:left-16 w-[200px] md:w-[250px] lg:w-[350px] h-[300px] md:h-[400px] lg:h-[550px] py-6'>

                    <Splide
                        ref={ splideRef }
                        options={ {
                            type: 'loop',
                            autoplay: true,
                            perPage: 3,
                            breakpoints: {
                                1024: {
                                    perPage: 2,
                                    height: '350px',
                                    // gap: '10px',
                                },
                                768: {
                                    perPage: 2,
                                    height: '350px',
                                },
                                640: {
                                    // perPage: 1,
                                    height: '250px',
                                    gap: '5px'
                                }
                            },
                            direction: 'ttb',
                            speed: 2000,
                            perMove: 1,
                            height: '500px',
                            width: '350px',
                            pagination: false,
                            gap: '10px',
                            focus: 'center'
                        } }>
                        {
                            latestVideos?.map( ( video, i ) =>
                                <SplideSlide key={ i } className='splide__slide1'>

                                    <SingleCard video={ video }></SingleCard>

                                    {/* <div className='relative cursor-pointer'>
                                    <Link to={ `/videoplay/${ video?.nt_categoryname }/${ video?.nt_ytid }` }>
                                        <img src={ `${ video?.nt_videothumbnail }` } alt="Slide 1" className='h-40 object-cover pb-1 rounded-md cursor-pointer' />
                                    </Link>
                                    <p className='text-orange-500 absolute z-20 top-0 text-center text-sm'>{ video?.nt_videotitle.length > 50 ? video?.nt_videotitle.slice( 0, 50 ) + ' .....' : video?.nt_videotitle }</p>
                                </div> */}
                                </SplideSlide>

                            )
                        }

                    </Splide>
                </div>
                <Link to={ `/videoplay/${ video[ 0 ] }/${ video[ 1 ] }` } className='absolute top-1/2 right-10 lg:right-32'>

                    <button className='headerbtn border-none mt-3 flex justify-center rounded-sm btn md:btn-wide  items-center gap-2 text-white bg-[#E50914] hover:bg-red-700 text-center px-7 py-5 '>
                        <FaPlay />
                        <span className='font-bold'>Play Now</span>
                    </button>


                    {/* <div className="button-container">
                        <svg tabIndex="0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 100 100" height="25%" width="25%">
                            <rect fill="#763AC2" height="8" width="90.62" y="51" x="5.04999" className="buttonSides"></rect>
                            <rect fill="#763AC2" transform="matrix(0.866025 -0.5 0.866025 0.5 1 59)" rx="8" height="57" width="57" className="buttonSides"></rect>
                            <g clipPath="url(#clip0_106_11)">
                                <rect fill="#A661FF" transform="matrix(0.866025 -0.5 0.866025 0.5 1 51)" rx="8" height="57" width="57" className="buttonTop"></rect>
                                <path fill="#763AC2" d="M64.2199 42.5L55.4353 61.4282L31.4353 47.5718L64.2199 42.5Z" className="buttonSides playSides"></path>
                                <g filter="url(#filter0_d_106_11)" className="glowAll">
                                    <path fill="white" d="M64.2199 42.5L55.4353 61.4282L31.4353 47.5718L64.2199 42.5Z"></path>
                                </g>
                                <g filter="url(#filter1_f_106_11)" opacity="0.5" className="glowMe">
                                    <path fill="white" d="M73.0478 36.9032L58.6665 67.8905L19.3761 45.2062L73.0478 36.9032Z"></path>
                                </g>
                            </g>
                            <path fill="url(#paint0_linear_106_11)" d="M64 43L32 47.8L32 8L64 8L64 43Z" opacity="0.5" className="glowMe"></path>
                            <defs>
                                <filter colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse" height="34.9282" width="48.7846" y="34.5" x="23.4353" id="filter0_d_106_11">
                                    <feFlood result="BackgroundImageFix" floodOpacity="0"></feFlood>
                                    <feColorMatrix result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" type="matrix" in="SourceAlpha"></feColorMatrix>
                                    <feOffset></feOffset>
                                    <feGaussianBlur stdDeviation="4"></feGaussianBlur>
                                    <feComposite operator="out" in2="hardAlpha"></feComposite>
                                    <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" type="matrix"></feColorMatrix>
                                    <feBlend result="effect1_dropShadow_106_11" in2="BackgroundImageFix" mode="normal"></feBlend>
                                    <feBlend result="shape" in2="effect1_dropShadow_106_11" in="SourceGraphic" mode="normal"></feBlend>
                                </filter>
                                <filter colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse" height="62.9873" width="85.6716" y="20.9032" x="3.37613" id="filter1_f_106_11">
                                    <feFlood result="BackgroundImageFix" floodOpacity="0"></feFlood>
                                    <feBlend result="shape" in2="BackgroundImageFix" in="SourceGraphic" mode="normal"></feBlend>
                                    <feGaussianBlur result="effect1_foregroundBlur_106_11" stdDeviation="8"></feGaussianBlur>
                                </filter>
                                <linearGradient gradientUnits="userSpaceOnUse" y2="8" x2="47" y1="48" x1="47" id="paint0_linear_106_11">
                                    <stop stopColor="white"></stop>
                                    <stop stopOpacity="0" stopColor="white" offset="1"></stop>
                                </linearGradient>
                                <clipPath id="clip0_106_11">
                                    <rect fill="white" transform="matrix(0.866025 -0.5 0.866025 0.5 1 51)" rx="8" height="57" width="57"></rect>
                                </clipPath>
                            </defs>
                        </svg>
                    </div> */}


                </Link>
            </div>




            {/* <Link to={ `/videoplay/${ video[ 0 ] }/${ video[ 1 ] }` }>
                <button className="btn bg-red-600 btn-wide text-white absolute hover:bg-red-500 right-32 top-1/2 border-none" >
                    <FaPlay className='animate-pulse text-lg mr-3' />
                    Play Now
                </button>
            </Link> */}



        </div>
    );
};

export default NewsTube;