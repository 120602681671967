
import Main from '../../Layouts/Main/Main';
import Home from '../../Pages/Home/Home';
import AllCategories from '../../Pages/AllCategories/AllCategories';
import VideoPlayer from '../../Pages/VideoPlayer/VideoPlayer'
import PrivacyPolicy from "../../Pages/PrivacyPolicy/PrivacyPolicy";
import AboutUs from "../../Pages/AboutUs/AboutUs";
import TermsConditions from "../../Pages/TermsConditions/TermsConditions";
import Live from "../../Categories/Live/Live";
import Trending from '../../Pages/Trending/Trending'
import Category from '../../Pages/CategoriesPages/Category/Category'
import Register from "../../Shared/Register/Register";
import LogIn from "../../Shared/LogIn/LogIn";

import { createBrowserRouter } from "react-router-dom";
import NotificationVideos from '../../Pages/NotificantionVideos/NotificationVideos';

export const router = createBrowserRouter( [

    {
        path: '/',
        element: <Main></Main>,
        children: [
            {
                path: '/',
                element: <Home />
            },
            {
                path: '/allcategories',
                element: <AllCategories></AllCategories>
            },
            {
                path: '/videoplay/:name/:id',
                element: <VideoPlayer></VideoPlayer>
            },
            {
                path: '/privacypolicy',
                element: <PrivacyPolicy></PrivacyPolicy>
            },
            {
                path: '/aboutus',
                element: <AboutUs></AboutUs>
            },
            {
                path: '/terms&conditions',
                element: <TermsConditions></TermsConditions>
            },
            {
                path: '/livepage',
                element: <Live></Live>
            },
            {
                path: '/notification/:id',
                element: <NotificationVideos></NotificationVideos>
            },
            {
                path: '/trending',
                element: <Trending></Trending>
            },
            {
                path: '/categories/:name',
                element: <Category></Category>
            }
        ]
    },
    {
        path: '/register',
        element: <Register></Register>
    },
    {
        path: '/login',
        element: <LogIn></LogIn>
    }
] )