import React from 'react';
import BangladeshiMediaNews from '../../Categories/BangladeshiMediaNews/BangladeshiMediaNews';
import BangladeshPolitics from '../../Categories/BangladeshPolitics/BangladeshPolitics';
import BusinessNews from '../../Categories/BusinessNews/BusinessNews';
import Entertainment from '../../Categories/Entertainment/Entertainment';
import Infotainment from '../../Categories/Infotainment/Infotainment';
import InternationalTvNews from '../../Categories/InternationalTvNews/InternationalTvNews';
import KanaksarwarNews from '../../Categories/KanaksarwarNews/KanaksarwarNews';
import LifeStyleNews from '../../Categories/LifeStyleNews/LifeStyleNews';
import Movie from '../../Categories/Movie/Movie';
import MusicVideo from '../../Categories/MusicVideo/MusicVideo';
import NewsVideos from '../../Categories/NewsVideos/NewsVideos';
import NewsReports from '../../Categories/NewsReports/NewsReports';
import NewsTube from '../../Categories/NewsTube/NewsTube';
import Technology from '../../Categories/Technology/Technology';
import ViralVideos from '../../Categories/ViralVideos/ViralVideos';
import SouthAsianNews from '../../Categories/SouthAsianNews/SouthAsianNews';
import UncategorizedNews from '../../Categories/UncategorizedNews/UncategorizedNews';

const AllCategories = () => {
    return (
        <div>
            <BangladeshiMediaNews></BangladeshiMediaNews>
            <BangladeshPolitics></BangladeshPolitics>
            <BusinessNews></BusinessNews>
            <Entertainment></Entertainment>
            <Infotainment></Infotainment>
            <InternationalTvNews></InternationalTvNews>
            <KanaksarwarNews></KanaksarwarNews>
            <LifeStyleNews></LifeStyleNews>
            {/* <Live></Live> */ }
            <Movie></Movie>
            <MusicVideo></MusicVideo>
            <NewsVideos></NewsVideos>
            <NewsReports></NewsReports>
            <NewsTube></NewsTube>
            <SouthAsianNews></SouthAsianNews>
            <Technology></Technology>
            <UncategorizedNews></UncategorizedNews>
            <ViralVideos></ViralVideos>

        </div>
    );
};

export default AllCategories;