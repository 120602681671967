import React from 'react';

import './HeaderBanner.css'
import { FaPlay } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const HeaderBanner = ( { video } ) => {


    return (
        <div className="showcase">
            <img src={ video?.nt_videothumbnail } alt="" className='object-cover max-h-screen' />

            <div className="overlay">
                <div className='ml-10 mt-28 font-bold text-left'>
                    <h2 className='text-3xl'>{ video?.nt_videotitle.length > 40 ? video?.nt_videotitle.slice( 0, 40 ) + '...' : video?.nt_videotitle }</h2>
                    <p className='text-2xl mt'>
                        Published At: { video?.published_at }
                    </p>
                    <div className='mt-32'>
                        <h1 className='text-xl '>Kanaksarwar</h1>
                        <Link to={ `/videoplay/${ video?.nt_categoryname }/${ video?.nt_ytid }` }>
                            <button className='headerbtn mt-2 flex justify-center btn btn-wide rounded-sm items-center gap-3 text-white bg-[#E50914] hover:bg-red-700 text-center'>
                                <span className='animate-pulse'>Play Now</span>
                                <FaPlay className='animate-pulse' />
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeaderBanner;