import React, { useState } from 'react';
import logo from '../../Assets/Logo/Logo-white.png'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

const Register = () => {
    const [ error, setError ] = useState( '' );
    const navigate = useNavigate()

    const handleRegister = ( e ) => {
        e.preventDefault()
        const form = e.target;
        const name = form.name.value;
        const email = form.email.value;
        const password = form.password.value;
        const confirmPassword = form.confirmPassword.value;
        if ( password === confirmPassword ) {

            const userDetails = {
                "nt_uname": name,
                "nt_uemail": email,
                "nt_userfullname": name,
                "nt_userpassword": password
            }

            axios.post( 'https://api.newstube.app/apiv3/api/registration.php', userDetails )
                .then( res => {
                    console.log( res );
                    navigate( '/' )
                    setError( '' )
                    form.reset();
                } )



        }
        else {
            setError( 'The passwords did not match' )
        }

    }
    return (
        <div className='min-h-screen flex justify-center items-center'>
            <div className='w-1/3 mx-auto bg-[#121111] flex flex-col justify-center items-center py-8 px-10 rounded-md'>
                <img src={ logo } alt="" className='w-1/3 mx-auto' />
                <h1 className='text-center text-2xl font-semibold my-3'>Create Your Account</h1>
                <form onSubmit={ ( e ) => handleRegister( e ) }>
                    <input type="text" name='name' placeholder='Full Name' className='w-full mb-5 px-4 py-2 rounded-sm bg-[#242121e5] focus:outline-none' />
                    <input type="email" name='email' placeholder='Email' className='w-full px-4 py-2 rounded-sm bg-[#242121e5] focus:outline-none' required />
                    <input type="password" name='password' placeholder='Password' className='w-full my-5 px-4 py-2 rounded-sm bg-[#242121e5] focus:outline-none' required />
                    <input type="password" name='confirmPassword' placeholder='Confirm Password' className='w-full px-4 py-2 rounded-sm bg-[#242121e5] focus:outline-none' required />

                    {
                        error && <p className='text-error'>{ error }</p>
                    }
                    <div className='flex items-center gap-3 justify-start w-full mt-4 text-xl'>
                        {/* <input type="checkbox" id="" /> */ }
                        <input type="checkbox" className="h-5 w-5 rounded-md border bg-white border-gray-500" required />
                        <span>I agree the terms and policy</span>
                    </div>

                    <input type="submit" value="Sign Up" className='w-full bg-[#23B7E5] text-white py-2 mt-5 rounded-sm cursor-pointer' />

                </form>
                <div className='mt-5'>
                    <h1>Already a member? <Link to='/login' className='text-[#23B7E5]'>Login here</Link></h1>
                </div>
            </div>
        </div>
    );
};

export default Register;