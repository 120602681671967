import React, { useState, useEffect } from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import { Link } from 'react-router-dom';
import SingleCard from '../../Shared/SingleCard/SingleCard';


const Infotainment = () => {
    const [ infotainmentVideo, setInfotainmentVideos ] = useState( [] );


    const url = 'https://api.newstube.app/apiv3/api/infotainment.php'
    useEffect( () => {
        fetch( url )
            .then( res => res.json() )
            .then( data => {
                // console.log( data.body );
                setInfotainmentVideos( data?.body )
            } )
    }, [] )


    return (
        <>
            {
                infotainmentVideo?.length &&
                <div className='w-11/12 mx-auto'>
                    <hr className='mt-32 mb-5' />
                    <div className='px-2'>
                        <h1 className='text-4xl font-semibold text-white mb-5'>Infotainment News</h1>
                        <Splide
                            options={ {
                                type: 'loop',
                                autoplay: true,
                                perPage: 4,
                                breakpoints: {
                                    1024: {
                                        perPage: 3,
                                        height: '170px',
                                        gap: '10px'

                                    },
                                    768: {
                                        perPage: 2,
                                        height: '190px',
                                    },
                                    640: {
                                        // perPage: 1,
                                        height: '150px',
                                        gap: '10px'
                                    }
                                },
                                speed: 2000,
                                perMove: 1,
                                height: '170px',
                                pagination: false,
                                gap: '10px',
                                focus: 'center'

                            } }>
                            {
                                infotainmentVideo?.map( ( video, i ) =>
                                    <SplideSlide key={ i }
                                    // className='splide__slide2 '
                                    >
                                        <SingleCard video={ video }></SingleCard>

                                        {/* <Link to={ `/videoplay/${ video?.nt_categoryname }/${ video?.nt_ytid }` } className="hero h-full rounded-lg transition ease-in-out delay-150 hover:-translate-y-5 hover:scale-100 duration-500 cursor-pointer" style={ { backgroundImage: `url(${ video?.nt_videothumbnail })` } }>
                                            <div className="hero-overlay infotainment"></div>
                                            {/* <div className="hero-content text-center text-neutral-content">
                                                <div className="relative">
                                                    <h1 className="mb-5 text-2xl font-bold absolute right-6 top-10 text-red-600">{ video?.nt_categoryname }</h1>
                                                     <button className="btn btn-primary">Get Started</button>
                                                </div>
                                            </div> */}
                                        {/* </Link> */ }
                                    </SplideSlide>

                                )
                            }

                        </Splide>
                    </div>
                </div>
            }
        </>
    );
};

export default Infotainment;