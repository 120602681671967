import React, { useState, useEffect } from 'react';
import CommonPage from '../../Components/CommonPage/CommonPage';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import SingleCard from '../../Shared/SingleCard/SingleCard';
// import '@splidejs/splide/dist/css/themes/splide-default.min.css';


const Live = () => {
    const [ liveVideos, setLiveVideos ] = useState( [] )



    useEffect( () => {
        window.scroll( 0, 0 )
        fetch( 'https://api.newstube.app/apiv3/api/liveshow.php' )
            .then( res => res.json() )
            .then( data => {
                setLiveVideos( data?.body )
                // console.log( data?.body );
            } )
    }, [] )

    return (
        <div className='w-11/12 mx-auto mt-16'>
            <h1 className='text-4xl font-bold pt-5'>Live </h1>
            <hr className='my-10' />
            {
                liveVideos?.length &&
                <CommonPage videos={ liveVideos }></CommonPage>
            }
            <h1 className='text-4xl font-bold pt-5'>Live </h1>
            <hr className='my-5' />
            {
                liveVideos?.length ?
                    <Splide
                        options={ {
                            type: 'loop',
                            autoplay: true,
                            perPage: 4,
                            speed: 2500,
                            // interval: 3000,
                            perMove: 1,
                            height: '170px',
                            pagination: false,
                            gap: '20px',
                            // focus: 'center'
                        } }>
                        {
                            liveVideos?.map( ( video, i ) =>
                                <SplideSlide key={ i } className='splide__slide2'>

                                    <SingleCard video={ video }></SingleCard>


                                    {/* <Link to={ `/videoplay/${ video?.nt_categoryname }/${ video?.nt_ytid }` } className=" rounded-lg cursor-pointer">
                                        <div className="card bg-base-300 image-full transition ease-in-out delay-150 hover:-translate-y-5 hover:scale-100 duration-500 cursor-pointer">
                                            <figure><img src={ `${ video?.nt_videothumbnail }` } alt="news" /></figure>
                                            <div className="card-body">
                                                <h2 className="card-title text-orange-200">{ video?.nt_videotitle }</h2>
                                            </div>
                                        </div>
                                    </Link> */}
                                </SplideSlide>

                                // <SplideSlide key={ i } >
                                //     <Link to={ `/videoplay/${ video?.nt_categoryname }/${ video?.nt_ytid }` } className="hero h-full rounded-lg  cursor-pointer" style={ { backgroundImage: `url(${ video?.nt_videothumbnail })` } }>
                                //         <div className="hero-overlay bg-opacity-30"></div>
                                //         <div className="hero-content text-center text-neutral-content">
                                //             <div className="max-w-md">
                                //                 {/* <h1 className="mb-5 text-2xl font-bold">{ video?.nt_videotitle }</h1> */ }
                                //                 {/* <button className="btn btn-primary">Get Started</button> */ }
                                //             </div>
                                //         </div>
                                //     </Link>
                                // </SplideSlide>

                            )
                        }

                    </Splide>
                    :
                    <></>
            }


        </div>
    );
};

export default Live;