import './App.css';
import { RouterProvider } from 'react-router-dom';
import { router } from './Routes/Routes/Routes';

function App () {
  return (
    <div data-theme="dark" className='bg-[#181616]'>
      <RouterProvider router={ router }>

      </RouterProvider>
    </div>
  );
}

export default App;
