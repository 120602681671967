import React from 'react';
import { Link } from 'react-router-dom';

import './SingleCard.css'
import { FaPlay } from 'react-icons/fa';

const SingleCard = ( { video } ) => {

    // console.log( video );

    return (
        <div className='relative cursor-pointer h-full duration-500 hover:gap-5 rounded-md card'>

            {/* // hover:border-l-4 border-red-700 */ }

            <img src={ `${ video?.nt_videothumbnail }` } alt="" className='relative h-full object-cover w-full rounded-md' />

            <div className='cardoverlay'>
                <div className='ml-5'>
                    <h2 className='font-bold mt-3 md:mt-8 text-lg text-white'>Kanaksarwar</h2>
                    <h2 className='text-sm text-white'>{ video?.nt_categoryname }</h2>

                    <Link to={ `/videoplay/${ video?.nt_categoryname }/${ video?.nt_ytid }` }>

                        <button className='headerbtn mt-5 md:mt-8 flex justify-center rounded-sm items-center gap-2 text-white bg-[#E50914] hover:bg-red-700 text-center btn btn-sm text-xs'>
                            <FaPlay />
                            <span>Play Now</span>
                        </button>
                    </Link>
                </div>
            </div>


            {/* <img src={ `${ video?.nt_videothumbnail }` } alt="" className=' h-full object-cover w-full rounded-md' /> */ }

            {/* video>?.nt_videotitle */ }

            {/* <Link to={ `/videoplay/${ video?.nt_categoryname }/${ video?.nt_ytid }` } className='w-full text-right h-full z-10 absolute top-0 transition ease-in-out delay-150 hover:-translate-y-10 hover:scale-125 duration-500 cursor-pointer'>
                <span className='text-8xl font-bold absolute right-0 -bottom-2 text-white '>{ i + 1 }</span>
            </Link> */}

        </div >
    );
};

export default SingleCard;